<template>
  <div>
    <v-dialog v-model="show_dialog" width="600">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Push Lead
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog()">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="push_lead_to_ltfs_form">
            <v-row class="ma-0">
              <v-col cols="6" class="pb-0">
                <div>
                  Customer Name
                </div>
                <div class="font-weight-black">
                  {{ customer_details.name }}
                </div>
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Mobile
                </div>
                <div class="font-weight-black">
                  {{ customer_details.mobile }}
                </div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <div>
                  Email
                </div>
                <div class="font-weight-black">
                  {{ customer_details.email }}
                </div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <div>
                  NBFC
                </div>
                <v-select
                  v-model="nbfc_name"
                  placeholder="Select NBFC"
                  outlined
                  dense
                  required
                  :rules="$rules.REQURIED_FILED"
                  :items="nbfc_list"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Loan Amount
                </div>
                <!-- <div class="font-weight-black" v-if="stage == 'PC'">
                  {{ customer_details.applied_amount }}
                </div> -->
                <!-- v-else -->
                <v-text-field
                  outlined
                  placeholder="Enter loan amount"
                  dense
                  required
                  type="number"
                  :rules="[$rules.AMOUNT] && loan_amount_rules"
                  v-model="loan_amount"
                  :hint="
                    `Min ₹${min_loan_amount.toLocaleString(
                      'en-IN'
                    )} - ₹${max_loan_amount.toLocaleString('en-IN')}`
                  "
                ></v-text-field>
                <!-- :rules="$rules.AMOUNT" -->
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Tenure
                </div>
                <!-- <div class="font-weight-black" v-if="stage == 'PC'">
                  {{ customer_details.tenure }} Months
                </div> -->
                <!-- v-else -->
                <v-select
                  v-model="tenure"
                  placeholder="Select Tenure"
                  outlined
                  dense
                  required
                  :rules="$rules.REQURIED_FILED"
                  item-text="text"
                  item-value="value"
                  :items="tenure_list"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pb-4">
          <v-btn
            color="secondary_2"
            rounded
            class="mx-1 white--text px-4"
            @click="handlePush()"
            depressed
            :loading="push_btn_loading"
            :disabled="push_btn_loading"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            Push
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      customer_id: "",
      stage: "",
      loan_id: null,
      customer_details: {},
      push_btn_loading: false,
      loan_amount: null,
      loan_amount_rules: [
        (v) => !!v || "Loan Amount is required",
        (v) =>
          (!isNaN(parseFloat(v)) &&
            v >= this.min_loan_amount &&
            v <= this.max_loan_amount) ||
          `Loan Amount should be between ${this.min_loan_amount} - ${this.max_loan_amount}`,
      ],
      min_loan_amount: 15000,
      max_loan_amount: 1100000,
      tenure: "",
      tenure_list: [
        { text: "3 Months", value: "3" },
        { text: "6 Months", value: "6" },
        { text: "9 Months", value: "9" },
        { text: "12 Months", value: "12" },
        { text: "18 Months", value: "18" },
        { text: "24 Months", value: "24" },
        { text: "30 Months", value: "30" },
        { text: "36 Months", value: "36" },
        { text: "42 Months", value: "42" },
        { text: "48 Months", value: "48" },
      ],
      nbfc_list: ["All", "Fibe", "BankSathi"],
      nbfc_name: "",
    };
  },
  computed: {},
  methods: {
    openPushToLtfs(stage, value) {
      // console.log("openPushToLtfs", value);
      this.show_dialog = true;
      this.stage = stage;
      // this.loan_id = value.loan_id;
      // this.customer_id = value.customer_id;
      this.customer_details = value;
      if (value.tenure) {
        this.tenure = JSON.stringify(value.tenure);
      }
      if (value.applied_amount) {
        this.loan_amount = value.applied_amount;
      }
    },

    closeDialog() {
      this.$refs.push_lead_to_ltfs_form.reset();
      this.show_dialog = false;
    },
    handlePush() {
      // if (this.stage == "PC") {
      // this.pushLead();
      // } else {
      if (this.$refs.push_lead_to_ltfs_form.validate()) {
        // if (this.loan_amount && this.tenure) {
        this.pushLead();
        // alert("Success")
      } else {
        this.showSnakeBar("error", "Please fill all the details");
      }
      // }
    },
    pushLead() {
      const self = this;
      self.push_btn_loading = true;
      let form = {};

      form["loan_id"] = this.customer_details.id;
      form["nbfc_name"] = this.nbfc_name;
      form["loan_amount"] = this.loan_amount;
      form["tenure"] = this.tenure;
      if (this.stage == "Pending By NBFC") {
        form["stage"] = "PC";
      } else {
        form["stage"] = this.stage;
      }

      const successHandler = () => {
        this.push_btn_loading = false;
        this.$refs.push_lead_to_ltfs_form.reset();
        this.$emit("update");
        this.show_dialog = false;
      };
      const finallyHandler = () => {
        self.push_btn_loading = false;
      };
      self.POST_request(
        self,
        self.$urls.PUSH_LEAD_TO_LTFS + `${this.customer_details.customer_id}/`,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
      // } else {
      //   this.showSnakeBar("error", "Please select a reason before rejecting");
      // }
    },
  },
};
</script>
